<template>
    <span>
        <component :is="$store.state.layout" />
    </span>
</template>

<script>
export default {
    components: {
        StudioAgency: () =>
            import("../../views/all-home-version/StudioAgency.vue"),
        StartUp: () => import("../../views/all-home-version/StartUp.vue"),
        DigitalAgency: () =>
            import("../../views/all-home-version/DigitalAgency.vue"),
        CreativePortfolio: () =>
            import("../../views/all-home-version/CreativePortfolio.vue"),
        CreativeAgency: () =>
            import("../../views/all-home-version/CreativeAgency.vue"),
        LandingHomeParticle: () =>
            import("../../views/all-home-version/LandingHomeParticle.vue"),
        MinimalPortfolio: () =>
            import("../../views/all-home-version/MinimalPortfolio.vue"),
    },
    watch: {
        iglesia() {
            this.SetLayout();
        },
    },
    data() {
        return {
            page: "StudioAgency",
        };
    },
    computed: {
        iglesia() {
            return this.$store.state.iglesia;
        },
        layout() {
            return this.$store.state.layout;
        },
    },

    created() {
        this.SetLayout();
    },

    methods: {
        SetLayout() {
            
            if (Number(this.iglesia.Layout) == 1) {
                this.$store.commit("set_Layout", "StudioAgency");
            } else if (Number(this.iglesia.Layout) == 2) {
                this.$store.commit("set_Layout", "StartUp");
            } else if (Number(this.iglesia.Layout) == 3) {
                this.$store.commit("set_Layout", "DigitalAgency");
            } else if (Number(this.iglesia.Layout) == 4) {
                this.$store.commit("set_Layout", "CreativePortfolio");
            } else if (Number(this.iglesia.Layout) == 5) {
                this.$store.commit("set_Layout", "CreativeAgency");
            } else if (Number(this.iglesia.Layout) == 6) {
                this.$store.commit("set_Layout", "LandingHomeParticle");
            } else if (Number(this.iglesia.Layout) == 7) {
                this.$store.commit("set_Layout", "MinimalPortfolio");
            }
        },
    },
};
</script>

<style lang="scss">
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
    .slider-video-bg.slide.slide-style-2 {
        padding-top: 160px;
        padding-bottom: 260px;
        min-height: auto;
    }
}
</style>
